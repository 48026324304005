import React from 'react';
import {apiRoutePrefix, RouteComponent} from '../../../RouteComponent';
import {Box, Link, Typography} from '@material-ui/core';

export default new RouteComponent(`${apiRoutePrefix}/introduction`, () => {

    return (
        <>
            <Box mb={ 3 }>
                <Typography variant="h4">Introduction</Typography>
            </Box>
            <Typography><strong>This API is currently in a beta state and revisions could be made at any time in the future.</strong></Typography>
            <Box my={ 3 }/>
            <Typography>Welcome to the Fishbowl REST API Documentation!</Typography>
            <Box my={ 2 }/>
            <Typography>The Fishbowl API follows the REST standard. We use the GET, POST, and DELETE verbs. The API
                endpoints accept JSON encoded objects and URL parameters.</Typography>
            <Box my={ 2 }/>
            <Typography>Fishbowl is an on-premise solution. When testing your integration, be sure to set up a sandbox
                environment so that you do not change data on your live server.</Typography>
            <Box my={ 2 }/>
            <Typography>The REST API matches the functionality found in the Fishbowl Client. Be sure you understand the
                workflow and use cases that you are implementing. This information can be found in the Documentation
                Wiki.</Typography>
            <Box mb={ 3 }/>
            <Typography>
                Related guide: <Link target="_blank" href="https://www.fishbowlinventory.com/wiki/Main_Page">Documentation
                Wiki</Link>
            </Typography>
        </>
    );
});
